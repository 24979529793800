<template>
<div>
  <a-radio-group v-model="formType" default-value="income" @change="handleTypeChange">
    <a-radio-button value="income">
      Приход
    </a-radio-button>
    <a-radio-button value="overhead">
      Приход по накладной
    </a-radio-button>
  </a-radio-group>
  <a-form :form="form" @submit="onSubmit" >
    <a-form-item v-if="formItems.overheadId" label="Идентификатор накладного">
      <a-input
        type="number"
        min="1"
        v-on:focusout="checkOverhead"
        v-on:keydown.enter="checkOverhead"
        :loading="overheadLoader"
        v-decorator="['OverheadId', { rules: [{
          required: true,
          message: 'Введите идентификатор накладного' }] }]" />
    </a-form-item>
    <a-form-item v-if="formItems.vendor" label="Поставщик">
      <a-select
        @change="selectVendor"
        placeholder="Поставщик"
        v-decorator="[
                          'VendorId',
                          { rules: [{ required: true,
                          message: 'Пожалуйста выберите Поставщика' }] }, ]">
        <a-select-option
          v-for="vendor in vendors"
          :key="vendor.Name"
          :value="vendor.Id">
          {{ vendor.Name }}
        </a-select-option>
      </a-select>
    </a-form-item>
    <a-form-item v-if="formItems.warehouse" label="Наименование склада">
      <a-select
        @change="selectWarehouse"
        placeholder="Склад"
        v-decorator="[
              'WarehouseId',
              { rules: [{ required: true,
              message: 'Пожалуйста выберите Склад' }] }, ]">
        <a-select-option
          v-for="warehouse in warehouses"
          :key="warehouse.Name"
          :value="warehouse.Id">
          {{ warehouse.Name }}
        </a-select-option>
      </a-select>
    </a-form-item>
    <a-form-item v-if="formItems.searchByAttribute" label="Поиск по атрибуту">
      <a-input placeholder="RED"
               ref='focused'
               v-on:keyup.enter="searchBar" style="width: 20%; margin: 1em 0" />
    </a-form-item>
    <a-row v-if="formItems.selectProduct">
      <a-col :span="12">
        <a-form-item label="Товар">
          <a-select
            :value="searchProductValue"
            placeholder="Введите наименование товара"
            style="width: 400px"
            :default-active-first-option="false"
            :show-arrow="false"
            :showSearch="true"
            :filterOption="filterProduct"
            @change="handleChange"
          >
            <a-select-option v-for="(product, index) in products" :key="index">
              {{ product.Name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item v-if="searchProduct" label="Количество">
          <a-input-number v-model="amountValue"
                          :min="1" @change="amountOnChange" />
        </a-form-item>
        <a-form-item v-if="searchProduct" label="Цена">
          <a-input-number v-model="priceValue"
                          :min="1" @change="priceOnChange" />
        </a-form-item>
      </a-col>

      <a-col :span="12" v-if="searchProduct">
        <a-form-item label="Атрибуты" v-if="searchProduct.Attributes">
          <a-input-group size="default" v-for="(attribute, index) in searchProduct.Attributes"
                         :key="index">
            <a-row :gutter="8">
              <a-col :span="5">
                <a-input disabled="disabled" :default-value="index" />
              </a-col>
              <a-col :span="8">
                <a-select
                          style="width: 120px"
                          @change="selectAttribute($event, index)">
                  <a-select-option v-for="(value, valueIndex) in attribute.Values"
                                   :key="valueIndex">
                    {{ value }}
                  </a-select-option>
                </a-select>
              </a-col>
            </a-row>
          </a-input-group>
        </a-form-item>
      </a-col>
      <a-col v-if="formItems.customAttributes && searchProduct" :span="24">
        <br>
        <a-card>
          <div slot="title"
               style="display: flex; justify-content: space-between; align-items: center">
            <span>Дополнительные аттрибуты для каждого товара</span>
            <div class="tags">
              <a-tag v-for="(type, index) in selectedTypes" :key="index"
                     closable
                     @close="removeType(index)">
                {{ type.Name }}
              </a-tag>
            </div>
            <a-button-group>
              <a-select style="width: 200px" @change="selectCustomAttribute">
                <a-select-option :disabled="!searchProduct"
                                 v-for="(attribute, index) in computedAttributes"
                                 :key="attribute.Id"
                                 :value="index">
                  {{ attribute.Name }}
                </a-select-option>
              </a-select>
              <a-button type="default"
                        :disabled="!searchProduct"
                        v-on:click="addCustomAttribute">Добавить</a-button>
            </a-button-group>
          </div>
          <a-card-grid v-for="(element, index) in customAttributes"
                       :key="index" style="width:25%;">
            <span><b>Позиция:</b> {{ (index+1) }}</span>
            <a-form-item v-for="(input, inputIndex) in element"
                         :key="inputIndex"
                         label-align="left"
                         style="width: 100%;"
                         :label="input.Name">
              <a-input v-if="input.Type === 'string'"
                       v-on:keyup.enter="goNextPosition(index, inputIndex)"
                       :ref="'field-' + ((selectedTypes.length*index)
                           + inputIndex)"
                       v-on:change="fillCustomAttribute($event,{
                             index: index,
                             type: input.Type,
                             inputIndex: inputIndex,
                           })"/>
              <a-input type="number" v-else-if="input.Type === 'integer'"
                       :min="1" style="width: 100%"
                       v-on:keyup.enter="goNextPosition(index, inputIndex)"
                       :ref="'field-' + ((selectedTypes.length*index)
                                  + inputIndex)"
                       v-on:change="fillCustomAttribute($event,{
                                    index: index,
                                    type: input.Type,
                                    inputIndex: inputIndex,
                                  })"/>
            </a-form-item>
          </a-card-grid>
        </a-card>
        <br>
      </a-col>
      <a-col :span="24" v-if="searchProduct">
        <a-button :disabled="!searchProduct" class="editable-add-btn" @click="handleAdd">
          Добавить товар
        </a-button>
      </a-col>
    </a-row>
    <a-form-item v-if="formItems.selectedProducts" label="Выбранные товары">
      <div class="ant-form-explain" v-if="!selectedProducts.length"
           style="color: #f5222d">Пожалуйста добавьте товары</div>
      <a-table bordered :data-source="selectedProducts" row-key="Id" :columns="columns">
        <template slot="name" slot-scope="text">
          {{ text }}
        </template>
        <template slot="attributes" slot-scope="item">
          <div v-for="(attributes, index) in item.Items" :key="index">
            <b>Атрибуты Позиции({{ (index+1) }})</b>:
            <div v-for="(attribute, attributeIndex) in attributes" :key="attributeIndex">
              <div>
                <i>{{ attribute.Name }}</i>: <u>{{ attribute.Value }}</u>
              </div>
            </div>
          </div>
        </template>
        <template slot="operation" slot-scope="text, record">
          <a-button-group>
<!--            <a-button type="default" v-on:click="editProduct(record, text)">-->
<!--              Редактировать-->
<!--            </a-button>-->
            <a-button type="danger">
              <a-popconfirm
                v-if="selectedProducts.length"
                title="Уверены что собираетесь удалить ?"
                @confirm="() => onDelete(record, text)"
              >
                <a href="javascript:;">Удалить</a>
              </a-popconfirm>
            </a-button>
          </a-button-group>
        </template>
      </a-table>
    </a-form-item>
    <a-form-item>
      <a-button v-if="!formItems.overheadId"
                type="primary"
                :loading="loading"
                @click="onSubmit">
        Приходовать
      </a-button>
      <a-button v-if="formItems.overheadId" type="secondary" @click="createOverhead">
        Накладной
      </a-button>
    </a-form-item>
  </a-form>
  <a-drawer
    :title="`Редактирование ` + edit.Name"
    placement="right"
    :closable="false"
    :visible="onEdit"
    width="300"
    @close="onClose">
    <a-form-item label="Количество">
      <a-input-number v-model="edit.Amount"
                      :min="1" @change="amountOnChange" />
    </a-form-item>
    <a-form-item label="Цена">
      <a-input-number v-model="edit.Price"
                      :min="1" @change="priceOnChange" />
    </a-form-item>
    <a-button v-on:click="saveEdit" type="success">Закрыть</a-button>
  </a-drawer>
  <a-modal
    title="Выберите накладной"
    :visible="modalVisible"
    @cancel="closeModal"
  >
    <a-table :columns="modalColumns" :data-source="overheads" size="small">
      <template slot="select" slot-scope="select">
        <a-button v-on:click="selectOverheadInModal(select)">Выбрать</a-button>
      </template>
    </a-table>
    <template slot="footer"><div></div></template>
  </a-modal>
</div>
</template>

<style scoped>
.ant-col.ant-form-item-label{
  width: 100%;
}
</style>

<script>
import { formatResponseValidatorFields } from '../../../helpers';

export default {
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'create' });
  },
  data() {
    return {
      formType: 'income',
      OverheadId: false,
      vendors: [],
      warehouses: [],
      VendorId: false,
      WarehouseId: false,
      dataSource: [],
      overheads: [],
      products: [],
      amountValue: 1,
      oldAmountValue: 1,
      priceValue: 1,
      searchProductValue: undefined,
      searchProduct: undefined,
      selectedProducts: [],
      loading: false,
      onEdit: false,
      editIndex: 0,
      attributes: [],
      defaultAttributes: [],
      selectedCustomAttribute: 0,
      selectedTypes: [],
      overHeadModal: false,
      overHeadRequired: false,
      modalVisible: false,
      toWarehouse: [],
      overheadLoader: false,
      modalColumns: [
        {
          title: 'Идентификатор накладного',
          dataIndex: 'OverheadId',
          key: 'OverheadId',
        },
        {
          title: 'Цена',
          key: 'Price',
          dataIndex: 'Price',
        },
        {
          title: '',
          scopedSlots: { customRender: 'select' },
        },
      ],
      formItems: {
        vendor: true,
        warehouse: true,
        selectProduct: true,
        customAttributes: true,
        selectedProducts: true,
        overheadId: false,
        searchByAttribute: false,
      },
      edit: {
        Name: '',
        Amount: 0,
        ProductId: 0,
        Price: 0,
      },
      selectedProductAttributes: [],
      columns: [
        {
          title: 'Наименование товара',
          key: 'Name',
          dataIndex: 'Name',
          width: '30%',
          scopedSlots: { customRender: 'Name' },
        },
        {
          title: 'Количество',
          key: 'Amount',
          dataIndex: 'Amount',
        },
        {
          title: 'Сумма',
          key: 'Price',
          dataIndex: 'Price',
        },
        {
          title: 'Атрибуты',
          key: 'Attributes',
          scopedSlots: { customRender: 'attributes' },
        },
        {
          title: '',
          key: 'operation',
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' },
        },
      ],
      existAttribute: false,
    };
  },
  // watch: {
  //   amountValue(to, from) {
  //     if (this.selectedTypes.length && to > from) {
  //       const type = this.selectedTypes.map((item) => ({
  //         AttributeId: item.AttributeId,
  //         Type: item.Type,
  //         Name: item.Name,
  //         Value: '',
  //       }));
  //       this.customAttributes.push(type);
  //     }
  //     if (this.selectedTypes.length && to < from) {
  //       this.customAttributes.pop();
  //     }
  //   },
  // },
  computed: {
    computedAttributes() {
      if (!this.searchProduct) {
        return [];
      }

      return this.serializeAttributes();
    },
    customAttributes: {
      get() {
        if (!this.searchProduct) {
          return [];
        }
        const attributes = [];
        if (this.selectedTypes.length) {
          for (let i = 0; i < this.amountValue; i += 1) {
            const type = this.selectedTypes.map((item) => ({
              AttributeId: item.AttributeId,
              Type: item.Type,
              Name: item.Name,
              Value: 'bug test',
            }));
            attributes.push(type);
          }
          return attributes;
        }
        return [];
      },
      set(value) {
        return value;
      },
    },
  },
  mounted() {
    this.fetchVendors();
    this.fetchWarehouses();
    this.fetchAttributes();
    this.fetchProducts();
  },
  methods: {
    selectCustomAttribute(index) {
      this.selectedCustomAttribute = index;
    },
    serializeAttributes(reverse = false) {
      const attributes = [];
      for (let i = 0; i < this.attributes.length; i += 1) {
        const exist = Object.keys(this.searchProduct.Attributes)
          .filter((item) => item === this.attributes[i].Name);
        if (!exist.length && !reverse) {
          attributes.push(this.attributes[i]);
        } else if (exist.length && reverse) {
          attributes.push(this.attributes[i]);
        }
      }
      return attributes;
    },
    fetchWarehouses() {
      this.$api.getWarehousesList(false, (response) => {
        const { data } = response.data;
        this.warehouses = data;
      });
    },
    selectWarehouse(value) {
      this.WarehouseId = value;
      if (this.formType === 'overhead' && !this.OverheadId) {
        const filters = {
          WarehouseId: this.WarehouseId,
        };
        const query = Object.keys(filters)
          .map((key) => filters[key] && `filter[${key}]=${filters[key]}`)
          .join('&');
        this.$api.getOverheads(query, (response) => {
          const { data } = response.data;
          this.overheads = data;
        });
        this.modalVisible = true;
      }
    },
    fetchAttributes() {
      this.$api.getAttributesList(false, (response) => {
        const { data } = response.data;
        this.attributes = data;
      });
    },
    fetchVendors() {
      this.$api.getVendors(false, (response) => {
        const { data } = response.data;
        this.vendors = data;
      });
    },
    fetchProducts() {
      this.$api.filterProducts('', (response) => {
        const { data } = response.data;
        this.products = data;
      });
    },
    onSubmit() {
      this.overHeadRequired = false;
      this.form.validateFields((err) => {
        if (!err) {
          this.loading = true;
          this.$api.inputStore({
            VendorId: this.VendorId,
            WarehouseId: this.WarehouseId,
            Products: this.selectedProducts,
          }, () => {
            this.$router.push({ name: 'invoices-income-list' });
            this.loading = false;
          }, () => {
            this.loading = false;
          });
        }
      });
    },
    selectVendor(value) {
      this.VendorId = value;
    },
    onCellChange(key, dataIndex, value) {
      const dataSource = [...this.dataSource];
      const target = dataSource.find((item) => item.key === key);
      if (target) {
        target[dataIndex] = value;
        this.dataSource = dataSource;
      }
    },
    onDelete(item) {
      const element = Object.keys(this.selectedProducts)
        .find((key) => this.selectedProducts[key].Id === item.Id);
      this.selectedProducts.splice(element, 1);
    },
    handleAdd() {
      if (this.customAttributes.length) {
        this.customAttributes.forEach((entry, key) => {
          const element = this.customAttributes[key];
          this.customAttributes[key] = element.concat(this.selectedProductAttributes);
        });
      } else {
        for (let i = 0; i < this.amountValue; i += 1) {
          this.customAttributes.push(this.selectedProductAttributes);
        }
      }
      const product = this.searchProduct;

      // const exists = this.selectedProducts.find((x) => x.ProductId === product.Id);

      // if (exists) {
      //   exists.Price = (this.amountValue * this.priceValue) + exists.Price;
      //   exists.Amount = this.amountValue + exists.Amount;
      // const Items = Object.assign({}, ...exists.Items);
      // exists.Items = [].concat(Items, this.customAttributes);
      // } else {

      this.customAttributes.map((item) => {
        if (item.length !== 0) {
          this.existAttribute = true;
        }
        return item;
      });
      const object = {
        ProductId: product.Id,
        Name: product.Name,
        Amount: this.amountValue,
        Price: this.priceValue,
      };
      if (this.existAttribute) {
        object.Items = this.customAttributes;
      }
      this.selectedProducts.push(object);
      this.amountValue = 1;
      // }
      this.searchProduct = undefined;
      this.searchProductValue = undefined;
      this.priceValue = 1;
      this.selectedTypes = [];
    },
    handleChange(value) {
      const { Name } = this.products[value];
      this.searchProduct = this.products[value];
      this.searchProductValue = Name;
    },
    amountOnChange() {},
    priceOnChange() {},
    editProduct(item) {
      this.onEdit = true;
      const { 0: product } = this.selectedProducts.filter((x) => x.ProductId === item.ProductId);
      this.edit = Object.assign(product);
    },
    onClose() {
      this.onEdit = false;
    },
    saveEdit() {
      const { index } = this.edit;
      this.selectedProducts[index] = this.edit;
      this.onEdit = false;
    },
    selectAttribute(index, attribute) {
      const attributeId = this.searchProduct.Attributes[attribute].Id;
      const value = this.searchProduct.Attributes[attribute].Values[index];
      const attributeKeys = Object.keys(this.searchProduct.Attributes);
      const keys = Object.entries(attributeKeys).reduce((ret, entry) => {
        const { 0: entryKey, 1: entryValue } = entry;
        ret[entryValue] = entryKey;
        return ret;
      }, {});
      this.selectedProductAttributes[keys[attribute]] = {
        AttributeId: attributeId,
        Name: attribute,
        Type: '',
        Value: value,
      };
    },
    addCustomAttribute() {
      const attribute = this.computedAttributes[this.selectedCustomAttribute];
      const exists = this.selectedTypes.filter((item) => item.AttributeId === attribute.Id);
      if (!exists.length) {
        this.selectedTypes.push({
          AttributeId: attribute.Id,
          Type: attribute.Type,
          Name: attribute.Name,
        });
      }
    },
    addAttribute() {
      const attribute = this.attributes[this.selectedCustomAttribute];
      const exists = this.customAttributes.filter((item) => item.AttributeId === attribute.Id);
      if (!exists.length) {
        this.customAttributes.push({
          AttributeId: attribute.Id,
          Value: '',
        });
      }
    },
    fillCustomAttribute($event, object) {
      const { index, inputIndex } = object;
      this.customAttributes[index][inputIndex].Value = $event.target.value;
    },
    removeType(index) {
      this.selectedTypes.splice(index, 1);
    },
    goNextPosition(index, inputIndex) {
      const target = ((this.selectedTypes.length * index) + inputIndex);
      const newTarget = target + 1;
      const totalLength = this.selectedTypes.length * this.customAttributes.length;
      if (totalLength > newTarget) {
        this.$refs[`field-${newTarget}`][0].$el.focus();
      }
    },
    createOverhead(e) {
      e.preventDefault();
      this.overHeadRequired = true;
      this.form.validateFields((err, values) => {
        if (!err) {
          const products = this.selectedProducts.map((item) => {
            const productWarehouses = item.Items.map((element) => element
              .map((subElement) => subElement.ProductWarehouseIds));
            return {
              Amount: Number(item.Amount),
              Price: item.Price,
              ProductId: item.ProductId,
              ProductWarehouseIds: productWarehouses.length
                ? productWarehouses[0].filter(
                  (elm, pos) => productWarehouses[0].indexOf(elm) === pos,
                ) : false,
            };
          });
          this.$api.overheadInputStore({
            WarehouseId: this.WarehouseId,
            OverheadId: Number(values.OverheadId),
            Products: products,
          }, ({ data, status }) => {
            const fields = formatResponseValidatorFields(data, values);
            if (status === 422) {
              this.form.setFields(fields);
            }
            this.$router.push({ name: 'income-details', params: { invoiceId: data.data.Id } });
          }, (response) => {
            const { status, data } = response;
            const fields = formatResponseValidatorFields(data, values);
            if (status === 422) {
              this.form.setFields(fields);
            }
          });
        }
      });
    },
    handleTypeChange(event) {
      if (event.target.value === 'overhead') {
        this.formItems.overheadId = true;
        this.formItems.vendor = false;
        this.formItems.customAttributes = false;
        this.formItems.selectProduct = false;
        this.selectedProducts = [];
        this.formItems.searchByAttribute = false;
        this.form.setFieldsValue({
          WarehouseId: '',
        });
      } else {
        this.selectedProducts = [];
        this.formItems.overheadId = false;
        this.formItems.vendor = true;
        this.formItems.customAttributes = true;
        this.formItems.selectProduct = true;
        this.formItems.searchByAttribute = false;
      }
    },
    searchBar(event) {
      const { value } = event.target;
      const filters = {
        Attribute: value,
      };
      const query = Object.keys(filters)
        .map((key) => filters[key] && filters[key].length && `filter[${key}]=${filters[key]}`)
        .join('&');
      this.$api.filterStorageProducts(query, (response) => {
        const { data } = response.data;
        if (data.length === 0) {
          this.$notification.warning({
            message: `Товар с атрибутом "${value}" не найден.`,
          });
        }
        if (data.length > 1) {
          this.modalVisible = true;
          this.products = data;
        } else if (data.length === 1) {
          const { 0: product } = data;
          this.searchProduct = product;
          this.searchProductValue = product.Name;
        }
      });
    },
    selectOverheadInModal(invoice) {
      this.form.setFieldsValue({
        OverheadId: invoice.OverheadId,
      });
      this.selectedProducts = invoice.Invoice.InvoiceItems
        .map((element) => ({
          ProductId: element.Product.Id,
          Name: element.Product.Name,
          Amount: element.Amount,
          Price: element.Price,
          ProductWarehouseIds: element.ProductWarehouseIds,
          Items: element.Items.map((item) => item.Attributes.map((attribute) => ({
            ProductWarehouseIds: item.ProductWarehouseId,
            Name: attribute.Name,
            Value: attribute.Value,
          }))),
        }));
      this.modalVisible = false;
    },
    closeModal() {
      this.modalVisible = false;
    },
    checkOverhead(event) {
      event.preventDefault();
      this.overheadLoader = true;
      const overHeadId = event.target.value;
      this.$api.checkOverhead(overHeadId, false, (response) => {
        this.overheadLoader = false;
        const { data } = response.data;
        this.toWarehouse = data.ToWarehouse;
        this.OverheadId = overHeadId;
        this.form.setFieldsValue({
          WarehouseId: data.ToWarehouse.Id,
        });
        this.WarehouseId = data.ToWarehouse.Id;
        this.selectedProducts = data.Invoice.InvoiceItems.map((invoice) => ({
          ProductId: invoice.Product.Id,
          Name: invoice.Product.Name,
          Amount: invoice.Amount,
          Price: invoice.Price,
          Items: invoice.Items.map((item) => item.Attributes.map((attribute) => ({
            ProductWarehouseIds: item.ProductWarehouseId,
            Name: attribute.Name,
            Value: attribute.Value,
          }))),
        }));
        this.$notification.success({
          message: `Накладной склада ${this.toWarehouse.Name}.`,
        });
      }, (error) => {
        const { status } = error;
        this.overheadLoader = false;
        this.selectedProducts = [];
        if (status === 404) {
          this.$notification.error({
            message: `Накладной с идентификатором ${overHeadId} не найден.`,
          });
          this.form.setFieldsValue({
            OverheadId: '',
          });
          this.form.setFieldsValue({
            WarehouseId: '',
          });
        }
      });
    },
    filterProduct(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
  },
};
</script>
